import React from 'react';
import RightsWrapper from '../../sessionProvider/RightsWrapper';
import Date from '../../general/Date';
import {RIGHTS} from '../../../constants/Rights';
import {NewsArticle} from '../../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class NewsDetail extends React.PureComponent {
    static propTypes = {
        newsArticle: NewsArticle.isRequired,
    };

    render() {
        const {newsArticle} = this.props;
        const description = newsArticle.html;
        const hasDescription = description != null && description != "";
        return hasDescription ? <div>
                <div
                    dangerouslySetInnerHTML={{__html: description}}
                />
                <RightsWrapper from={RIGHTS.MARKETING}>
                    <div className="mt-2 secondary-text-color">
                    <span className="mr-2">
                        <Trans>Vytvoril: </Trans>
                        {' ' + newsArticle.author + ', '}
                    </span>
                        <span className="mr-2">
                        <Trans>Posledná zmena: </Trans>
                            {' '}
                            <Date dateString={newsArticle.last_change}/>
                    </span>
                    </div>
                </RightsWrapper>
            </div> : <div>
                <Trans>Novinka nemá detailnejší popis.</Trans>
            </div>;
    }

}

export default NewsDetail;