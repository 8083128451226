import React from 'react';
import withDataHOC from '../dataProvider/withDataHOC';
import locationHOC from '../locationProvider/locationHOC';
import Helmet from '../project/Helmet'
import NavigateButton from '../general/NavigateButton';
import NewsDetail from './newsDetail/NewsDetail';
import {Icon} from 'antd';
import {GLOBAL_DATA} from '../../constants/globalData';
import {News as NewsDef} from '../../constants/propTypesDefinitions';
import {getQueryParam, getQueryParamNumber} from '../../lib/url';
import {QUERY_PARAMS} from '../../constants/navigation';
import {Location} from '../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class NewsDetailPage extends React.PureComponent {
    static propTypes = {
        location: Location.isRequired,
        [GLOBAL_DATA.NEWS]: NewsDef.isRequired,
    };

    render() {
        const {[GLOBAL_DATA.NEWS]: news, location} = this.props;
        const newsId = getQueryParamNumber(location, QUERY_PARAMS.ID_NEWS);
        const newsArticle = news.find(a => a.id == newsId);
        return <React.Fragment>
            <Helmet
                title={newsArticle != null ? newsArticle.name : t`Novinka`}
            />
            <div className="m-3">
                <NavigateButton
                    isBackNavigation={true}
                    className="my-3"
                >
                    <Icon type="left"/>
                    <Trans>Späť</Trans>
                </NavigateButton>
                { newsArticle != null ?
                    <NewsDetail
                        newsArticle={newsArticle}
                    />
                    : null
                }
            </div>
        </React.Fragment>;
    }

}

export default locationHOC(
    withDataHOC([GLOBAL_DATA.NEWS])(NewsDetailPage)
);